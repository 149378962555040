@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200, 200i, 400,400i,700,700i,900,900i);/* --------------------------------------------
    TEMPLATE
-----------------------------------------------*/
.title {
  font-weight: 800;
  text-align: center; }
.title.dark {
    color: #363636; }
.title.light {
    color: #fff; }
.heading {
  text-align: center;
  background: #fff;
  position: relative;
  padding: 1em 1em;
  margin: 0; }
.heading:after {
  content: " ";
  display: block;
  width: 100px;
  height: 1px;
  margin: 20px auto 0px;
  background: #4fbfa8; }
p, li {
  font-size: .8em; }
.heading.title {
  font-size: 2em; }
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }
h2 {
  font-size: 2rem; }
h3 {
  font-size: 1.75rem; }
h4 {
  font-size: 1.5rem; }
p {
  margin-top: 0;
  margin-bottom: 1rem; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 576px) {
  p, li {
    font-size: 1em; } }
@media (min-width: 768px) {
  .heading.title {
    font-size: 2.5em; } }
@media (min-width: 992px) {
  .heading {
    padding: 1em 5em; } }
/* --------------------------------------------
    BASE STYLES
-----------------------------------------------*/
*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
body {
  font-family: "Nunito Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left; }
a:focus {
  outline: 2px solid #4FBF8F; }
a:hover {
  text-decoration: none; }
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 1.5s ease-out;
  transition: opacity 1.5s ease-out; }
.fade-enter,
.fade-leave-to {
  opacity: 0; }
ul {
  list-style: none;
  padding: 0; }
.component {
  position: relative; }
.component--services {
  background: #fff;
  position: relative; }
.component--clients {
  background: #fff;
  position: relative; }
.component--footer {
  background: #4FBF8F;
  position: relative; }
.component--cta {
  background: #4FBF8F;
  position: relative; }
.component--design {
  display: none; }
.component--design.is-active {
    display: block; }
.component--develop {
  display: none; }
.component--develop.is-active {
    display: block; }
.component--portfolio {
  position: relative;
  padding-top: 65px; }
.component--portfolio hr {
    margin-top: 20px;
    margin-bottom: 20px; }
.btn-io {
  padding: .65em 2em;
  font-weight: 600;
  background-color: transparent;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
  outline: #4FBF8F;
  font-size: 1rem; }
.btn-io:focus {
    outline: #4FBF8F; }
.columns {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -.75rem;
  margin-right: -.75rem;
  margin-top: -.75rem; }
.column {
  display: block;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  padding: .75rem; }
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
.col-112 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }
.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }
.column-half {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  max-width: 100%;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
.img-fluid, .img-thumbnail {
  max-width: 100%;
  height: auto; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 576px) {
  .container {
    max-width: 540px; } }
@media (min-width: 768px) {
  .column-half {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .container {
    max-width: 720px; } }
@media (min-width: 1200px) {
  .container.component--portfolio {
    max-width: 1400px !important; } }
/* --------------------------------------------
    PORTFOLIO PAGE STYLES
-----------------------------------------------*/
.portfolio--content-wrapper {
  padding: 30px 15px; }
.portfolio--content-wrapper p {
    max-width: 500px; }
.portfolio--content-wrapper h1.mt-3 {
    font-size: 2em; }
.portfolio__container {
  margin-bottom: 10em;
  min-height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
.portfolio-img__col {
  border-bottom: .5px solid #ccc;
  padding-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
.portfolio-nav {
  position: relative;
  margin-top: 20px;
  width: 100%;
  z-index: 1001; }
.portfolio-nav a {
    padding: 0 5px;
    color: #2b2b2b;
    font-size: 20px;
    display: block;
    text-decoration: none; }
.portfolio-nav a.left:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
.portfolio-nav a.right:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
.portfolio-nav span {
    font-size: 20px; }
.portfolio-tags {
  text-align: left; }
.portfolio-copy a {
  color: #4FBF8F; }
.portfolio-cta {
  padding: 8px 15px;
  background-color: #4FBF8F;
  color: #fff;
  border-radius: 2px;
  font-size: 16px;
  border: none;
  margin-top: 10px; }
.portfolio-cta a {
    color: #fff;
    text-decoration: none; }
.portfolio-cta:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03); }
.portfolio-list h4 {
  margin: 0 0 10px; }
.portfolio-list li {
  margin-bottom: 10px; }
.portfolio-list li:first-child {
    display: none; }
.portfolio-list .portfolio-list__technology {
  -webkit-columns: 2;
          columns: 2; }
.portfolio-list ul.portfolio-list__contributions li:before {
  content: '+';
  margin-right: 5px; }
.col-first {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }
.col-last {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }
.portfolio_img-sizer {
  max-width: 700px; }
@-webkit-keyframes arrow-hover-left {
  0% {
    padding: 0 10px; }
  100% {
    padding: 0 15px 0 0; } }
@keyframes arrow-hover-left {
  0% {
    padding: 0 10px; }
  100% {
    padding: 0 15px 0 0; } }
@-webkit-keyframes arrow-hover-right {
  0% {
    padding: 0 10px; }
  100% {
    padding: 0 0 0 25px; } }
@keyframes arrow-hover-right {
  0% {
    padding: 0 10px; }
  100% {
    padding: 0 0 0 25px; } }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 768px) {
  .col-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .col-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .component--portfolio {
    padding-top: 100px; }
  .portfolio--content-wrapper {
    padding: 0 40px 0 5%; }
    .portfolio--content-wrapper h1.mt-3 {
      font-size: 2.5em; }
  .portfolio-img__col {
    border-bottom: none;
    padding-bottom: 0; }
  .portfolio-counter {
    text-align: left; } }
/* --------------------------------------------
    NAVIGATION
-----------------------------------------------*/
.nav {
  position: fixed;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  background-color: #fff;
  width: 100%;
  z-index: 10000;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 30px;
  height: 66px;
  border-bottom: 4px solid rgba(79, 191, 143, 0.4);
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0; }
.nav svg#logo {
    color: #4FBF8F;
    fill: #4FBF8F;
    stroke: #4FBF8F;
    height: 100%;
    width: 35px;
    margin-top: 10px; }
.nav .nav-logo {
    opacity: 0; }
.nav .nav-logo.active {
      opacity: 1;
      stroke-dasharray: 8000;
      stroke-dashoffset: 8000;
      -webkit-animation: dash 0.8s linear forwards;
              animation: dash 0.8s linear forwards;
      -webkit-transition: all 100ms ease;
      transition: all 100ms ease; }
.nav li:nth-of-type(1) {
    padding-bottom: 5px; }
.nav li:nth-of-type(2) {
    margin-left: auto; }
.nav li:nth-of-type(3) {
    padding: 0; }
.nav li a {
    cursor: pointer;
    color: #888; }
.nav li a:hover {
      text-decoration: none; }
.nav button.btn-io {
    border: none;
    padding: 10px 15px;
    color: #888; }
.nav button.btn-io:focus {
      outline: 1px solid #4FBF8F;
      color: #fff;
      background: #4FBF8F;
      border-radius: 0; }
.nav .nav-item--selection {
    font-size: 14px;
    color: #888;
    -webkit-transition: none;
    transition: none;
    border: 2px solid transparent; }
.nav .nav-item--selection.nav-item-span {
      border: solid #4FBF8F;
      border-width: 0 2px 0 0;
      height: 34px;
      margin: 0 -3px; }
.nav .nav-item--selection.nav-item-span.active {
      display: none; }
.nav .nav-item--selection.active {
      background: transparent;
      color: #363636;
      border: 2px solid #4FBF8F;
      border-radius: 0; }
.nav .nav-item--selection.active span {
        display: none;
        border: none; }
.nav .nav-item--selection span {
      font-size: 30px;
      margin-top: -4px;
      display: block;
      height: 50px;
      font-weight: 100;
      padding: 5px 0;
      cursor: pointer;
      color: #4FBF8F; }
.nav .nav-item--selection.nav-item--contact.btn-io {
      background: transparent;
      color: #363636;
      border: 2px solid #4FBF8F;
      border-radius: 0; }
.nav .nav-item--selection.nav-item--contact.btn-io a {
        text-decoration: none; }
.nav .nav-item--selection.nav-item--contact.btn-io:active, .nav .nav-item--selection.nav-item--contact.btn-io:focus {
        background: #4FBF8F;
        color: #fff !important;
        -webkit-transition: all 100ms ease-in-out;
        transition: all 100ms ease-in-out; }
.nav .nav-item--selection.nav-item--contact.btn-io:hover, .nav .nav-item--selection.nav-item--contact.btn-io:visited {
        text-decoration: underline;
        outline: none; }
.bar {
  border-top: 4px solid #4FBF8F;
  width: 0;
  display: block;
  top: 62px;
  z-index: 10010;
  position: fixed; }
@-webkit-keyframes dash {
  from {
    stroke-dashoffset: -1500; }
  to {
    stroke-dashoffset: 0; } }
@keyframes dash {
  from {
    stroke-dashoffset: -1500; }
  to {
    stroke-dashoffset: 0; } }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 768px) {
  .nav .nav-item--selection {
    font-size: 16px; } }
/* --------------------------------------------
    HERO
-----------------------------------------------*/
.hero {
  background-color: #fff;
  height: 225vh;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden; }
.hero__container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden; }
.hero__row {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  height: 100%; }
.hero__copy {
  width: 100%;
  color: #4FBF8F;
  text-align: center; }
.hero__copy h2 {
    font-size: 2rem; }
.hero__copy .hero__copy-intro {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 50%;
    opacity: 1;
    width: 75%; }
.hero__copy .hero__copy-intro h2 {
      font-size: 1.5em;
      font-weight: 800; }
.hero__copy .hero__copy-intro h4 {
      font-size: 1.2em;
      color: #363636;
      padding: 0 0.5em;
      font-weight: 300; }
.hero__copy .hero__copy-outro {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 10%;
    opacity: 1;
    width: 75%; }
.hero__copy .hero__copy-outro h2 {
      font-weight: 300; }
.hero__content {
  position: absolute;
  z-index: 10020;
  width: 100vw;
  height: 100vh;
  margin: 0; }
.hero__left,
.hero__right {
  position: absolute;
  width: 50%;
  height: 100%;
  max-height: 85vh; }
.hero__left .content_container,
  .hero__right .content_container {
    visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 80%;
    -ms-flex-align: end;
    -webkit-box-align: start;
            align-items: flex-start;
    position: absolute;
    -ms-flex-item-align: center;
        align-self: center;
    height: 100%;
    background: #fff; }
.hero__left .content_container h1,
    .hero__right .content_container h1 {
      text-align: center; }
.hero__left .content_container h3,
    .hero__right .content_container h3 {
      color: #4FBF8F;
      margin: 10px 0;
      font-size: 1.55em;
      display: block; }
.hero__left .content_container p,
    .hero__right .content_container p {
      color: #363636; }
.hero__left {
  left: -50%;
  bottom: 0;
  cursor: pointer; }
.hero__left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-image: url("/static/img/face_left_color.png");
    -webkit-filter: brightness(1) contrast(1.5) grayscale(1);
            filter: brightness(1) contrast(1.5) grayscale(1); }
.hero__left .content_container {
    right: 25px; }
.hero__left.active::before {
    background-image: none; }
.hero__left.active .hero__buttons .hero__button-1 {
    -webkit-transition: 0ms linear;
    transition: 0ms linear;
    visibility: hidden; }
.hero__left.active .content_container {
    visibility: visible; }
.hero__left.active .arrow {
    opacity: 1; }
.hero__left:hover::before {
    -webkit-filter: brightness(1) contrast(1.5) grayscale(1);
            filter: brightness(1) contrast(1.5) grayscale(1); }
.hero__left:hover .hero__button-2 {
    -webkit-transform: scale(1);
            transform: scale(1);
    outline: #4FBF8F; }
.hero__left:hover .hero__button-2 span {
      color: #4FBF8F; }
.hero__left .arrow {
    position: absolute;
    font-size: 24px;
    bottom: 25px;
    right: 25px;
    font-weight: 200;
    color: #4FBF8F;
    -webkit-animation: arrow-down 3s ease-in-out infinite forwards;
            animation: arrow-down 3s ease-in-out infinite forwards;
    opacity: 0;
    display: none; }
.hero__right {
  right: -50%;
  bottom: 0;
  cursor: pointer; }
.hero__right::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/static/img/face_right_color.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom left;
    -webkit-filter: brightness(1) contrast(1.5) grayscale(1);
            filter: brightness(1) contrast(1.5) grayscale(1); }
.hero__right .content_container {
    left: 25px; }
.hero__right.active::before {
    background-image: none; }
.hero__right.active .hero__buttons .hero__button-2 {
    -webkit-transition: 0ms linear;
    transition: 0ms linear;
    visibility: hidden; }
.hero__right.active .content_container {
    visibility: visible; }
.hero__right.active .arrow {
    opacity: 1; }
.hero__right:hover::before {
    -webkit-filter: brightness(1) contrast(1.5) grayscale(1);
            filter: brightness(1) contrast(1.5) grayscale(1); }
.hero__right:hover .hero__button-1 {
    -webkit-transform: scale(1);
            transform: scale(1);
    outline: #4FBF8F; }
.hero__right:hover .hero__button-1 span {
      color: #4FBF8F; }
.hero__right .container {
    visibility: hidden; }
.hero__right .arrow {
    position: absolute;
    font-size: 24px;
    bottom: 25px;
    left: 25px;
    font-weight: 200;
    color: #4FBF8F;
    -webkit-animation: arrow-down 3s ease-in-out infinite forwards;
            animation: arrow-down 3s ease-in-out infinite forwards;
    opacity: 0;
    display: none; }
.hero__buttons {
  z-index: 1001;
  width: 100vw; }
.hero__buttons button.btn-io {
    border: none; }
.hero__buttons button.btn-io:focus {
      outline: 3px solid #4FBF8F; }
.hero__buttons button.btn-io:active {
      outline: none; }
.hero__buttons .hero__button {
    color: #363636;
    text-align: center;
    display: block;
    position: absolute;
    margin: 0 auto;
    top: 20%;
    font-size: 1em; }
.hero__buttons .hero__button h1 {
      margin: 0;
      font-size: 1.5em;
      font-weight: 400; }
.hero__buttons .hero__button span {
      -webkit-transition: color 100ms linear;
      transition: color 100ms linear;
      line-height: 1.5;
      display: block;
      font-weight: 800; }
.hero__buttons .hero__button-1 {
    right: 0%;
    opacity: 0; }
.hero__buttons .hero__button-2 {
    left: 0%;
    opacity: 0; }
/*----- HERO MOUSE ANIMATION ------- */
.mouse-icon {
  position: fixed;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: 2px solid #4FBF8F;
  border-radius: 16px;
  height: 40px;
  width: 24px;
  display: block;
  opacity: 1; }
.mouse-icon .wheel {
  -webkit-animation-name: drop;
          animation-name: drop;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
          animation-play-state: running; }
.mouse-icon .wheel {
  position: relative;
  border-radius: 10px;
  background: #4FBF8F;
  width: 3px;
  height: 4px;
  top: 4px;
  margin-left: auto;
  margin-right: auto; }
@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0; }
  30% {
    top: 10px;
    opacity: 1; }
  100% {
    top: 25px;
    opacity: 0; } }
@keyframes drop {
  0% {
    top: 5px;
    opacity: 0; }
  30% {
    top: 10px;
    opacity: 1; }
  100% {
    top: 25px;
    opacity: 0; } }
@-webkit-keyframes arrow-down {
  0% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); } }
@keyframes arrow-down {
  0% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); } }
@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 576px) {
  .hero__right .arrow,
  .hero__left .arrow {
    display: block; }
  .hero__copy .hero__copy-intro h2 {
    font-size: 2.5em; }
  .hero__copy .hero__copy-intro h4 {
    font-size: 1.5em; } }
@media (min-width: 768px) {
  .hero__right:hover::before {
    -webkit-filter: grayscale(0.1) brightness(1) contrast(1.3);
            filter: grayscale(0.1) brightness(1) contrast(1.3);
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out; }
  .hero__left:hover::before {
    -webkit-filter: grayscale(0.1) brightness(1) contrast(1.4);
            filter: grayscale(0.1) brightness(1) contrast(1.4);
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out; }
  .hero__content .content_container {
    width: 60%; }
  .hero__buttons .hero__button {
    font-size: 1.2em; }
    .hero__buttons .hero__button.hero__button-1 {
      right: 40%; }
    .hero__buttons .hero__button.hero__button-2 {
      left: 40%; }
  .hero__cover {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; } }
@media (min-width: 992px) {
  .hero__buttons .hero__button {
    top: 40%;
    font-size: 1.5em; }
    .hero__buttons .hero__button.hero__button-1 {
      right: 30%; }
    .hero__buttons .hero__button.hero__button-2 {
      left: 30%; } }
@media (min-width: 1200px) {
  .hero__row {
    height: 100vh; } }
/* --------------------------------------------
    POSTER GRID
-----------------------------------------------*/
.poster-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  position: relative;
  background: #fff;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
.poster-tile {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  height: 350px;
  position: relative;
  z-index: 1;
  margin-top: 65px; }
.poster-tile span {
    display: block;
    text-align: center;
    color: #4FBF8F;
    margin-top: 25px; }
.poster-tile h3 {
    text-align: center;
    margin: 10px 0; }
.poster-tile.poster-tile--disabled h3 {
    color: rgba(0, 0, 0, 0.5); }
.poster-tile.poster-tile--disabled.flipped .poster-tile__front {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg); }
.poster-tile.poster-tile--disabled.flipped .poster-tile__back {
    -webkit-transform: rotateY(0);
            transform: rotateY(0); }
.poster-tile .poster-tile__title-mobile {
    margin-top: -45px;
    font-size: 1em;
    font-weight: 800;
    padding: 10px 0;
    display: block;
    position: relative;
    text-align: center;
    color: #363636; }
.poster-tile .poster-tile__title-mobile-span {
      border-bottom: 1px solid #4fbfa8;
      display: inline; }
a.poster-tile.poster-tile__last-child {
  text-decoration: none;
  color: #fff;
  text-decoration: none; }
.poster-tile__front,
.poster-tile__back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
.poster-tile__front {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  background-size: cover;
  background-position: center center; }
.poster-tile__back {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
.poster-tile__back .poster-tile__back-content .sharing-bar {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px 20px; }
.poster-tile__back .poster-tile__back-content--inner {
    padding: 25px;
    color: #000;
    max-width: 350px;
    margin: 0 auto; }
.poster-tile__back .poster-tile__description {
    font-size: 16px;
    text-align: center; }
.portfolio--cta {
  background: #4FBF8F;
  position: relative;
  padding: 4em 0; }
.portfolio--cta .container {
    text-align: center;
    color: #fff; }
.portfolio--cta .container h4 {
      font-size: 32px; }
.portfolio--cta .container h2 {
      font-weight: 600; }
.portfolio--cta .cta-btn {
    background: #363636;
    color: #fff;
    padding: 10px 20px;
    margin: 20px auto 10px;
    font-size: 16px;
    display: block;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 2px 2px 2px rgba(68, 68, 68, 0.6);
            box-shadow: 2px 2px 2px rgba(68, 68, 68, 0.6);
    min-width: 177px; }
.portfolio--cta .cta-btn svg {
      margin: 0 10px;
      color: #4FBF8F;
      font-size: 1.3em;
      vertical-align: middle; }
.portfolio--cta .cta-btn:hover {
      -webkit-box-shadow: 4px 4px 4px rgba(68, 68, 68, 0.6);
              box-shadow: 4px 4px 4px rgba(68, 68, 68, 0.6); }
.portfolio--cta .cta-btn:focus {
      -webkit-box-shadow: 8px 8px 8px rgba(68, 68, 68, 0.6);
              box-shadow: 8px 8px 8px rgba(68, 68, 68, 0.6); }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 576px) {
  .poster-tile {
    -webkit-perspective: 576px;
            perspective: 576px;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%; }
  .portfolio--cta .cta-btn {
    margin: 1em .5em;
    display: inline; } }
@media (min-width: 768px) {
  .poster-tile {
    -webkit-perspective: 768px;
            perspective: 768px;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    margin-top: 0; }
    .poster-tile:hover .poster-tile__front, .poster-tile:focus .poster-tile__front {
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg); }
    .poster-tile:hover .poster-tile__back, .poster-tile:focus .poster-tile__back {
      -webkit-transform: rotateY(0);
              transform: rotateY(0); }
    .poster-tile .poster-tile__title-mobile, .poster-tile .poster-tile__title-mobile-span {
      display: none; } }
@media (min-width: 992px) {
  .poster-tile {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    -webkit-perspective: 992px;
            perspective: 992px;
    height: 400px;
    margin-top: .5em; } }
@media (min-width: 1200px) {
  .poster-tile {
    -webkit-perspective: 1200px;
            perspective: 1200px; } }
@media (min-width: 1700px) {
  .poster-tile {
    height: 500px;
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    -webkit-perspective: 1700px;
            perspective: 1700px; } }
@media (min-width: 1900px) {
  .poster-tile {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    -webkit-perspective: 1900px;
            perspective: 1900px; } }
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
.embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
.embed-responsive-16by9 {
  padding-bottom: 56.25%; }
.embed-responsive-4by3 {
  padding-bottom: 75%; }
.embed-responsive-1by1:before {
  padding-top: 100%; }
.embed-responsive:before {
  display: block;
  content: ""; }
.section .box .content figure.image {
  margin: 0 auto 2em; }
.services__list-title {
  margin-top: 30px;
  margin-bottom: 8px;
  color: #4FBF8F; }
img.design {
  width: 80%; }
svg.skill-icon {
  height: 80px;
  width: auto;
  stroke: #4FBF8F;
  fill: #4FBF8F; }
.services__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding-bottom: 5em;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%; }
.services__container h3 {
    font-weight: 400; }
.services__container .services__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: auto;
    width: 80%;
    text-align: center;
    margin: 0 .3em 1.5em;
    border: 2px solid #4FBF8F;
    padding: 15px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
.services__container .services__item-design {
    border: none;
    margin: 0; }
.services__container .services__item-design .services__list-title h3 {
      color: #363636;
      font-weight: 600; }
.services__container .services__item-design:nth-child(1) {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; }
.services__container .services__item-design:nth-child(2) {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
.services__container .services__item-design:nth-child(n + 3) {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; }
.services__list {
  -webkit-columns: 1;
          columns: 1;
  padding: 0;
  text-align: center;
  -webkit-column-gap: 10px;
          column-gap: 10px; }
.services__list span {
    font-size: 0.8em;
    color: #8c9093; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 576px) {
  .services__list {
    -webkit-columns: 2;
            columns: 2;
    text-align: left; } }
@media (min-width: 768px) {
  .services__list {
    padding: 0 3em;
    -webkit-column-gap: 30px;
            column-gap: 30px; }
  img.design {
    width: 60%; } }
@media (min-width: 992px) {
  .services__container .services__item {
    margin: 0;
    width: 48%;
    padding: 25px; }
  .services__container .services__item-design {
    border: none; }
    .services__container .services__item-design:nth-child(1) {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
    .services__container .services__item-design:nth-child(n + 2) {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; }
    .services__container .services__item-design p {
      padding: 0 20px;
      text-align: left; }
  .services__list {
    padding: 0 3em;
    -webkit-column-gap: 60px;
            column-gap: 60px; } }
/* --------------------------------------------
    CLIENTS
-----------------------------------------------*/
.clients-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 1em; }
.clients {
  border-top: 1px solid #e2e6ea;
  padding: 2em 0 9em; }
.clients .client-grid {
    padding: 1.5em 0;
    display: inline-block; }
.clients .client {
    display: block;
    padding: .25em;
    text-align: center; }
.clients .client-logo {
    width: 45%; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 768px) {
  .clients-container {
    padding: 0 5em; }
  .clients .client-logo {
    width: 24%; } }
/* --------------------------------------------
    TEMPLATE
-----------------------------------------------*/
.container.cta {
  height: 200px;
  max-width: 1140px;
  margin: 0 auto;
  position: relative; }
.cta-box {
  background: #363636;
  display: block;
  margin: -60px 2em 0;
  padding: 2.25rem 3em;
  z-index: 1001;
  position: absolute;
  color: #fff;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #293347;
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #293347;
  border-radius: 10px; }
.cta-box .button {
    color: #fff;
    padding: .75em 2em;
    font-weight: 400;
    background-color: transparent;
    border: 2px solid #4FBF8F;
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease; }
.cta-box .button:hover, .cta-box .button:focus {
      background: #4FBF8F;
      color: #fff; }
.cta-box .title {
    color: #fff;
    font-size: 1.7em; }
.cta-box .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-align: center; }
.cta-box .content .item {
      padding: 1em;
      width: auto; }
.cta-box .content .item.middle {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
.cta-box .content .item.right {
      text-align: center; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 768px) {
  .cta-box .content {
    text-align: left;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .cta-box .content .item {
      width: 33%; }
  .cta-box .title {
    color: #fff;
    font-size: 2.2em; } }
/* --------------------------------------------
    SOCIAL SHARING COMPONENT STYLES
-----------------------------------------------*/
.sharing-bar a {
  margin: 0 10px;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear; }
.sharing-bar a:first-of-type {
    margin-left: 0; }
.sharing-bar a:last-of-type {
    margin-right: 0; }
.sharing-bar a:hover {
    color: #ec2429; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
/* --------------------------------------------
    FOOTER
-----------------------------------------------*/
footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff; }
footer svg.footer-logo {
  color: #fff;
  fill: #fff;
  stroke: #fff;
  height: 100%;
  width: 40px;
  margin-top: 10px;
  stroke-width: 40px; }
footer .title {
  font-weight: 300;
  font-size: 1.2rem;
  margin: 1rem auto;
  max-width: 300px;
  opacity: 1;
  color: #fff;
  text-align: center; }
footer .social-icons {
  margin: 1rem auto 3rem; }
.svg-inline--fa.fa-fw {
  width: 1.2em; }
.footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 170px; }
.social-icons {
  font-size: .8em; }
.social-icons a.button {
  background-color: transparent;
  color: #fff;
  border-radius: 60%;
  margin: 7em .3rem;
  padding: 12px 14px;
  border: 2px solid rgba(255, 255, 255, 0.3); }
.social-icons a.button:hover, .social-icons a.button:focus {
    background: #fff;
    color: #4FBF8F;
    border: 2px solid #fff; }
.copyright {
  margin: .5rem;
  font-size: 0.9em;
  opacity: 0.7; }
/* --------------------------------------------
    MEDIA QUERIES
-----------------------------------------------*/
@media (min-width: 576px) {
  margin-top: 90px; }
@media (min-width: 768px) {
  .social-icons a.button {
    margin: 7em 1em; }
  .footer__container {
    margin-top: 0; }
  footer .title {
    font-size: 1.6em; } }
